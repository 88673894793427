import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, useNavigate} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.scss';
import { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { HashLink } from 'react-router-hash-link';


function CustomNavbar() {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
   
  return (
    <>
    {/* Sidebar navigation sart */}
    <div className='sidebar-navigation'>
      <header className="header">
            <nav className="navbar p-0">
                <div className="menu-wrapper">
                    <ul className={`menu ${sidebarOpen ? 'show' : ''}`}>
                        <li className="menu-block">
                            <span className="close-menu" onClick={() => { setSidebarOpen(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                    <path fill="#252a32" fill-rule="evenodd" d="M17.778.808l1.414 1.414L11.414 10l7.778 7.778-1.414 1.414L10 11.414l-7.778 7.778-1.414-1.414L8.586 10 .808 2.222 2.222.808 10 8.586 17.778.808z" />
                                </svg>
                                 {/* <img src="/amicus-logo.png"/> */}
                            </span>
                        </li>
                        <Navbar.Collapse id="navbarScroll" className='menu--sidebar'>
                            <Nav
                                className="my-2 my-lg-0"
                                navbarScroll 
                            >   
 
                                <NavDropdown title="Services" id="navbarScrollingDropdown">

                                <NavDropdown.Item><HashLink smooth to='/services/business-transformation-consultants' className='navlink'>Business Transformation</HashLink></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item><HashLink smooth to='/services/investor-relations' className='navlink'>Investor Relations</HashLink></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item><HashLink smooth to='/services/business-diagnostics-and-strategic-business-consultant' className='navlink'>Business Diagnostic</HashLink></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item><HashLink smooth to='/services/sme-ipo-consultants' className='navlink'>SME IPO</HashLink></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item><HashLink smooth to='/services/financial-consultant-services' className='navlink'>Finance Consulting Services</HashLink></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item><HashLink smooth to='/services/virtual-cfo' className='navlink'>Virtual-CFO Services</HashLink></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item><HashLink smooth to='/services/merger-acquisitions' className='navlink'>Merger& Acquisitions</HashLink></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item><HashLink smooth to='/services/esg-advisory' className='navlink'>ESG Advisory</HashLink></NavDropdown.Item>
                                    
                                </NavDropdown>
                                
                                <NavDropdown title="Industries" id="navbarScrollingDropdown">
                                    <NavDropdown.Item href="#action4">Logistics</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action4">E-commerce</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action4">Retail</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action4">Pharma & Chemicals</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action4">Industrial</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action4">Hospitality</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action4">Education</NavDropdown.Item>
                                </NavDropdown>

                                
                                <Nav.Link><NavLink to='/blog' className='navlink'>Blog</NavLink></Nav.Link>
                                <Nav.Link><NavLink to='/grow-trees' className='navlink'>Grow Trees</NavLink></Nav.Link>
                                <Nav.Link><NavLink to='/company' className='navlink'>About Us</NavLink> </Nav.Link>
                                <Nav.Link><NavLink to='/contact' className='navlink'>Contact</NavLink></Nav.Link>
                                 

                                {/* <Nav.Link href="#" disabled> Link</Nav.Link> */}
                            </Nav>
                            {/* <Form className="d-flex justify-content-end">
                                <Button className='btn btn-cta' variant="light"><NavLink to='/career' className='navlink'>Career</NavLink></Button>
                            </Form> */}
                        </Navbar.Collapse>
                        
                        <Nav.Link><NavLink to='/services/business-transformation-consultants' className='navlink-sidebar'>Business Transformation</NavLink></Nav.Link>
                        <Nav.Link><NavLink to='/services/investor-relations' className='navlink-sidebar'>Investor Relations</NavLink></Nav.Link>
                        <Nav.Link><NavLink to='/contact' className='navlink-sidebar'>Contact</NavLink></Nav.Link>
                        <div className='sidebar-content-wrapper'>
                        <img src='./esg-advisory.jpg' alt='sidebar-image' />
                            <p>Amicus objectively partners with organizations to drive transformation and roadmap through the improvement of critical business efficiencies, addressing the structural realignments and curtailing process ineffectiveness</p>
                           
                            <div className='sidebar-social-icons'>
                                {/* <a target='_blank' href='https://twitter.com/i/flow/login?redirect_after_login=%2FAmicusllp' style={{color:'black'}}><TwitterIcon style={{fontSize:'32px'}}/></a> */}
                                <a href='https://www.linkedin.com/company/amicus-insolvency-turnaround-services-llp/' target='_blank' style={{color:'black'}}><LinkedInIcon style={{fontSize:'32px'}}/></a>
                                <a target='_blank' href='https://www.instagram.com/amicusgrowthadvisors/?igshid=YmMyMTA2M2Y%3D' style={{color:'black'}}><InstagramIcon style={{fontSize:'32px'}}/></a>

                                <a href='whatsapp://send?text=Thankyou for contacting Us!&phone=+919820075360' target='_blank' style={{color:'black'}}><WhatsAppIcon style={{fontSize:'32px'}}/></a>
                            </div>
                        </div>
                        
                    </ul> 
                </div>
            </nav>
        </header>
    </div>
    {/* Sidebar navigation end */}

    {/* main navigation start  */}
    <nav className="navbar navbar-expand-md navbar-light main-navbar fixed-top py-0">
        <div className="container p-0">
            <div>
                <MenuIcon className='open-sidebar-icon' onClick={() => { setSidebarOpen(true) }} sx={{ verticalAlign: "-webkit-baseline-middle" }} style={{fontSize:'32px', paddingTop:'7px'}}/>
                <a className="navbar-brand pb-2" onClick={() => {navigate('/')}}>
                    <img src="/amicus-logo.png" alt="Amicus-Logo" className='img-fluid' style={{paddingTop:'5px',cursor:'pointer'}}/> <p className='tagline'>Your Growth <br /> Partner</p>
                </a>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end menu--main" id="navbarNavDropdown"> 
                <ul className="navbar-nav">
                    <li className="nav-item dropdown services-mm megamenu">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Our Services </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Container className='border-0' style={{padding:'20px 0px'}}>
                        <Row className='align-items-center' >
                            <Col md={12} className='megamenu ' style={{paddingLeft:'60px', color:'black'}}>
                                <h2>Our Services</h2>
                                <p>All of our services hinge around our promise <br/>of  delivering brand awareness. </p>
                                {/* <Nav.Link><NavLink to='/company' className='navlink'>Company</NavLink> </Nav.Link> */}
                                {/* <NavLink to='/company' className='navlink'><button className='megamenu-button'>Let's Talk</button></NavLink> */}
                            </Col>
                            <Col md={4} className='megamenu-image' style={{paddingLeft:'60px'}}>
                                <img src="/megamenu-services.jpg" alt="megamenu Image" className='img-fluid' style={{boxShadow: '1px 1px 6px #000'}}/>
                            </Col>
                            
                            <Col md={4} style={{paddingLeft:'60px'}}>
                                <NavDropdown.Item><HashLink smooth to='/services/business-transformation-consultants' className='navlink'>Business Transformation</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/business-diagnostics-and-strategic-business-consultant' className='navlink'>Business Diagnostic</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/sme-ipo-consultants' className='navlink'>SME IPO</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/investor-relations' className='navlink'>Investor Relations</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/financial-consultant-services' className='navlink'>Finance Consulting Services</HashLink></NavDropdown.Item>
 
                            </Col>
                            <Col md={4} style={{paddingLeft:'70px'}}>
                                <NavDropdown.Item><HashLink smooth to='/services/startup-consulting-and-advisory-services' className='navlink'>Startup Advisory</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/board-advisory' className='navlink'>Board Advisory</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/virtual-cfo' className='navlink'>Virtual-CFO Services</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/merger-acquisitions' className='navlink'>Merger& Acquisitions</HashLink></NavDropdown.Item>
                                <NavDropdown.Item><HashLink smooth to='/services/esg-advisory' className='navlink'>ESG Advisory</HashLink></NavDropdown.Item>
                            </Col>
                           
                        </Row>
                    </Container>
                        </ul>
                    </li>
                    <li className="nav-item dropdown industries-mm megamenu">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Industries </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Container className='border-0' style={{padding:'20px 0px'}}>
                        <Row className='align-items-center' >
                            <Col md={12} className='megamenu ' style={{paddingLeft:'60px', color:'black'}}>
                                <h2>Our Industries</h2>
                                <p>All of our services hinge around our promise <br />of  delivering brand awareness.</p>
                                {/* <Nav.Link><NavLink to='/company' className='navlink'>Company</NavLink> </Nav.Link> */}
                                {/* <NavLink to='/company' className='navlink'><button className='megamenu-button'>Let's Talk</button></NavLink> */}
                            </Col>
                            <Col md={4} className='megamenu-image' style={{paddingLeft:'60px',paddingTop:'20px' }} >
                                <img src="/megamenu-industry.jpg" alt="megamenu Image" className='img-fluid' style={{boxShadow: '1px 1px 6px #000'}}/>
                            </Col>
                            <Col md={4} style={{paddingLeft:'70px'}}>
                            <NavDropdown.Item><NavLink to='/logistics' className='navlink'>Logistics</NavLink></NavDropdown.Item>
                            <NavDropdown.Item><NavLink to='/d2c' className='navlink'>Ecommerce</NavLink></NavDropdown.Item>
                            <NavDropdown.Item><NavLink to='/retail' className='navlink'>Retail</NavLink></NavDropdown.Item>
                            
                                
                            </Col>
                            <Col md={4} style={{paddingLeft:'60px'}}>
                            <NavDropdown.Item><NavLink to='/manufacturing' className='navlink'>Manufacturing</NavLink></NavDropdown.Item>
                            <NavDropdown.Item><NavLink to='/hospitality' className='navlink'>Hospitality</NavLink></NavDropdown.Item>
                            <NavDropdown.Item><NavLink to='/edtech' className='navlink'>EdTech</NavLink></NavDropdown.Item>
                            </Col>
                           
                        </Row>
                    </Container>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => {navigate('/blog')}}>Blog</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => {navigate('/report')}}>Reports</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => {navigate('/grow-trees')}}>Sustainability</a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" onClick={() => {navigate('/company')}}>About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => {navigate('/contact')}}>Contact</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link  ms-4"onClick={() => {navigate('/career')}}>Career</a>
                    </li> */}
                </ul>
            </div>
        </div>
    </nav>


    
    </>
    
  );
}

export default CustomNavbar;