import React from 'react';
import './BT.scss';

function BT() {
  return (
    <div className='BT-Container'>
        <div className='BT-MainBox'>
            <div className='BT-Box1'>
                <div className='BT-Circle1'></div>
            </div>

            <div className='BT-Box2'>
            <div className='BT-Circle1'></div>
            <div className='BT-Circle1'></div>
            </div>
            
            <div className='BT-Box3'>
            <div className='BT-Circle1'></div>
            <div className='BT-Circle1'></div>
            </div>
        </div>
    </div>
  )
}

export default BT